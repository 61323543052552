<template>
  <div class="ddj-offer">
    <!-- <div class="main-Title bgff"><h2>上传记录</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="list-filter mb10">
        <el-form :model="filter" label-width="80px" size="small">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="6" :xl="4">
              <el-form-item label="Country:" label-width="70px">
                <el-select
                  v-model="filter.country"
                  placeholder="Please select"
                  class="w100"
                  filterable
                  :clearable="true"
                >
                  <el-option label="All" value="" />
                  <el-option
                    v-for="item in options.country"
                    :key="item.countryCode"
                    :value="item.countryCode"
                    :label="item.countryCode"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :xl="4">
              <el-form-item label="OS:" label-width="30px">
                <el-select
                  v-model="filter.os"
                  placeholder="Please select OS"
                  class="w100"
                  :clearable="true"
                >
                  <el-option label="All" value="" />
                  <el-option
                    v-for="item in options.os"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :xl="4">
              <el-form-item label="id:" label-width="20px">
                <el-input v-model="filter.id" placeholder="Please enter"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :xl="4">
              <el-form-item label="Name:" label-width="60px">
                <el-input v-model="filter.name" placeholder="Please enter"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :xl="4">
              <el-form-item label="数据包类型:" label-width="90px">
                <el-select
                  v-model="filter.dataSourceType"
                  placeholder="Please select type"
                  class="w100"
                >
                  <el-option label="All" value="" />
                  <el-option
                    v-for="item in options.dataSourceType"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="2">
              <el-form-item label-width="0px">
                <el-button
                  type="primary"
                  size="mini"
                  @click="getList('filter')"
                  :loading="loading.list"
                  >Search</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          :data="list"
          v-loading="loading.list"
          ref="listTableRef"
          class="w100"
          size="mini"
          border
          highlight-current-row
        >
          <el-table-column label="ID" prop="id"></el-table-column>
          <el-table-column label="Country" prop="country"></el-table-column>
          <el-table-column label="OS" prop="os"></el-table-column>
          <el-table-column label="版本号" prop="osVersion">
            <template slot-scope="scope">
              {{ scope.row.osVersion }}~{{ scope.row.osVersionMax }}
            </template>
          </el-table-column>
          <el-table-column label="设备数" prop="deviceCount"></el-table-column>
          <el-table-column label="名称" prop="deviceName">
            <template slot-scope="scope">
              {{ scope.row.deviceName }}
              <i
                class="el-icon-edit"
                @click="updateDeviceName(scope.row.id, scope.row.deviceName)"
              ></i>
            </template>
          </el-table-column>
          <el-table-column label="活跃度" prop="activation">
            <template slot-scope="scope">
              {{ convertToPercentage(scope.row.activation) }}
            </template>
          </el-table-column>
          <el-table-column label="状态" prop="status" align="center"></el-table-column>
          <el-table-column label="oss地址" prop="ossSavedUrl"></el-table-column>
          <el-table-column label="数据包类型" prop="dataSourceType" width="90px" align="center">
            <template slot-scope="scope">{{
              scope.row.dataSourceType | dataSourceTypeName(options.dataSourceType)
            }}</template>
          </el-table-column>
          <el-table-column label="time" prop="time">
            <template slot-scope="scope"> {{ scope.row.time }}{{ scope.row.unit }} </template>
          </el-table-column>
          <el-table-column label="count_day" prop="countDay"></el-table-column>
          <el-table-column label="更新时间" prop="updateTime"></el-table-column>
          <el-table-column label="创建时间" prop="createTime"></el-table-column>
          <el-table-column label="操作" width="100" fixed="right" align="center">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                size="mini"
                @click="edit(scope.row.id)"
              ></el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                size="mini"
                @click="del(scope.row.id)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <div v-if="isShowPage" class="pagination-container pt-10" align="center">
          <el-pagination
            :page-size="pages.pageSize"
            :total="pagesTotal"
            background
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
  import optionData from '@/assets/js/optionData';
  import {
    fetchHoloDataUploadPage,
    fetchHoloDataUploadDel,
    updateDeviceNameAsyncInfo,
  } from '@/api/DDJ/v3/holoData.js';
  import { fetchCountryList } from '@/api/DDJ/v3/country';
  export default {
    components: {},
    data() {
      return {
        filter: {},
        options: {
          country: [],
          os: optionData.osOption,
          dataSourceType: [
            { label: '定时更新', value: '0' },
            { label: '实时', value: '1' },
          ],
        },
        list: [],
        diallog: {
          updata: false,
        },
        loading: {
          list: false,
        },
        isUpdataPost: {
          id: '',
          isUpdate: '',
        },
        random: '',
        rules: {},
        pages: {
          pageNum: 1,
          pageSize: 20,
        },
        pagesTotal: 0,
        isShowPage: false,
      };
    },
    mounted() {
      this.getCountry();
      this.getList();
    },
    computed: {},
    filters: {
      dataSourceTypeName(v, arr) {
        for (var i = 0; i < arr.length; i++) {
          if (arr[i].value === v) {
            return arr[i].label;
          }
        }
      },
    },
    methods: {
      updateDeviceName(id, deviceName) {
        this.$prompt('请输入数据包名称', '修改数据包名称', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputValue: deviceName,
          closeOnClickModal: false,
          inputValidator: (v) => {
            if (v == '') {
              return '不能为空！';
            }
          },
          inputErrorMessage: '',
        })
          .then(({ value }) => {
            updateDeviceNameAsyncInfo({
              id: id,
              deviceName: value,
            }).then((res) => {
              if (res.code === 200) {
                this.getList();
                this.$message.success(res.message);
              } else {
                this.$message.error(res.message);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '取消输入',
            });
          });
      },

      convertToPercentage(value) {
        if (!isNaN(value)) {
          return (value * 100).toFixed(0) + '%';
        } else {
          return '';
        }
      },
      // 国家
      getCountry() {
        fetchCountryList().then((res) => {
          this.options.country = res.result;
        });
      },
      // 获取列表
      getList(types) {
        if (types == 'filter') {
          this.pages.pageNum = 1;
          this.isShowPage = false;
        }
        // let query = Object.assign(this.filter, this.pages);
        let query = Object.assign({}, this.filter, this.pages);
        if (query.name) {
          query.name = query.name.replace(/%/g, '%25');
          query.name = query.name.replace(/\+/g, '%2B');
        }
        this.loading.list = true;
        console.log('==========================666666666666666666,', query);
        fetchHoloDataUploadPage(query).then((res) => {
          if (res.code == 200) {
            let data = res.result;
            this.list = [];
            this.list = data.records;
            this.pagesTotal = data.total;
          } else {
            this.$message.error(res.message);
            this.list = [];
          }
          this.loading.list = false;
          this.isShowPage = true;
        });
      },
      // 编辑
      edit(id) {
        this.$router.push({
          path: '/click/holo/upload/edit',
          query: {
            id: id,
          },
        });
      },
      // 删除
      del(id) {
        this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: 'maxWidthx100',
        })
          .then(() => {
            console.log(id);
            fetchHoloDataUploadDel({
              id: id,
            }).then((res) => {
              if (res.code == 200) {
                console.log(res);
                this.getList();
                this.$message({
                  type: 'success',
                  message: '删除成功!',
                });
              } else {
                this.$message.error(res.message);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
      },
      // 分页监听
      handleCurrentChange(val) {
        this.pages.pageNum = val;
        this.getList();
      },
    },
  };
</script>

<style></style>
